<template>
  <div class="footer">
    <footer :style="flag ? 'box-shadow: 0 -2px 4px 2px rgba(89,144,255,.2);' : ''">
      <span class="span" @click="jump">copyright ©️ 品课教育体验技术部出品</span>
      <a class="span" href="tel://4000-390308">联系电话：400&nbsp;039&nbsp;0308</a>
    </footer>
  </div>
</template>
<script>
export default {
  props:{
    flag:{
      type:Boolean,
      default:true
    }
  },
  methods:{
    jump(){
      window.open('https://www.miit.gov.cn/')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer{
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
}
footer{
  width: 100%;
  height: 50px;
  color: #000;
  font-weight: 600;
  font-size: .8vw;
  text-align: center;
  line-height: 50px;
  // box-shadow: 0 -2px 4px 2px rgba(89,144,255,.2);
  position: absolute;
  bottom: 0;
  left: 0;
  .span{
    cursor: pointer;
    padding: 0 15px;
    border-right: 1px solid #000;
  }
  .span:nth-child(2) {
    border: none;
    display: inline;
  }
}
</style>